import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import classNames from "classnames";
import {
  withStyles,
  withTheme,
  createMuiTheme,
} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Link from "@material-ui/core/Link";
import Badge from "@material-ui/core/Badge";

import IconLocationOn from "@material-ui/icons/LocationOn";
import IconBooth from "@material-ui/icons/PersonPin";
import IconImage from "@material-ui/icons/Image";
import IconToday from "@material-ui/icons/Today";
import IconLanguage from "@material-ui/icons/Language";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h3: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#E44A4B",
    },
  },
});

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 12,
    marginBottom: theme.spacing.unit * 20,
    [theme.breakpoints.up(960)]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing.unit * 12,
      marginBottom: theme.spacing.unit * 20,
    },
  },
  table: {
    width: "100%",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
    // whiteSpace: "nowrap"
  },
  button: {
    margin: theme.spacing.unit,
    textAlign: "left",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 5,
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  grid: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#FFFFFF",
  },
  stepper: {
    padding: "0px",
    paddingLeft: "24px",
  },
  newsBG: {
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing.unit * 3,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: theme.spacing.unit * 6,
    },
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 2}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
});

//**********NOTE: NEED TO setup the same start/end date in appBar.jsx as well
// const tradeshows = [
//   {
//     name: "Taipei AMPA & Autotronics 2024",
//     location: "Nangang Exhibition Center, Hall 1",
//     locationLink: "https://goo.gl/maps/HNDRUpM2iq7aEbYe7",
//     boothLink: "",
//     booth: "L0727a",
//     start: "2024/04/17",
//     end: "2024/04/20",
//   },
//   {
//     name: "Automechanika Frankfurt 2024",
//     location: "Messe Frankfurt",
//     locationLink: "https://goo.gl/maps/WgGekxiy9wA6RNSx8",
//     boothLink: "",
//     booth: "TBD",
//     start: "2024/09/10",
//     end: "2024/09/14",
//   },
// ];

// const news = [
//   {
//     title: "Taipei AMPA & Autotronics 2024 Invitation",
//     description: "",
//     embed:
//       "https://www.linkedin.com/embed/feed/update/urn:li:share:7164090779798364161",
//     link: "https://www.linkedin.com/posts/linkchamp_powerinverter-invertercharger-puresinewave-activity-7164090780985360386-9U1y",
//   },
//   {
//     title: "Automechanika Dubai 2023",
//     description: "",
//     embed:
//       "https://www.linkedin.com/embed/feed/update/urn:li:share:7114526217139089409",
//     link: "https://www.linkedin.com/posts/linkchamp_powerinverter-invertercharger-puresinewave-activity-7114526217805983744-Bg76",
//   },
//   {
//     title: "Taipei AMPA & Autotronics 2023",
//     description: "",
//     embed:
//       "https://www.linkedin.com/embed/feed/update/urn:li:share:7051837605155373056",
//     link: "https://www.linkedin.com/posts/linkchamp_ampa-inverter-powerinverter-activity-7051837605864210432-A0vi",
//   },
// ];

const tradeShowHistory = [
  {
    decade: 2020,
    events: [
      {
        year: "2024",
        shows: ["AMPA, Taipei", "Automechanika, Frankfurt"],
        links: [
          "https://www.linkedin.com/feed/update/urn:li:activity:7186174750677762048",
          "https://www.linkedin.com/feed/update/urn:li:activity:7239535172914700288",
        ],
      },
      {
        year: "2023",
        shows: ["AMPA, Taipei", "Automechanika, Dubai"],
        links: [
          "https://www.linkedin.com/feed/update/urn:li:activity:7051837605864210432",
          "https://www.linkedin.com/feed/update/urn:li:activity:7114526217805983744",
        ],
      },
      {
        year: "2022",
        shows: [
          "HKTDC International Sourcing Show, Online",
          "Automechanika, Frankfurt",
          "HKTDC Hong Kong Electronics Fair (Autumn Edition), Online",
        ],
        links: [
          "https://isshow.hktdc.com/online/en/",
          "https://www.linkedin.com/feed/update/urn:li:activity:6977282290561286144",
          "https://event.hktdc.com/fair/hkelectronicsfairae-en/Exhibitor/HKTDC-Hong-Kong-Electronics-Fair-Autumn-Edition/Linkchamp-Co-Ltd/1S0043976/",
        ],
      },
      {
        year: "2021",
        shows: [
          "HKTDC International Sourcing Show, Online",
          "AMPA, Online",
          "Computex CyberWorld , Online",
          "HKTDC Hong Kong Electronics Fair (Autumn Edition), Online",
        ],
        // links: [
        //   "https://isshow.hktdc.com/online/en/",
        //   "https://www.taipeiampa.com.tw/en/product/5DBEC9D7DC3034D6B1AC5535CCA2A6F6/info.html",
        //   "https://show.computex.biz/ExhibitorDetail.aspx?scom_id=975",
        //   "https://event.hktdc.com/fair/hkelectronicsfairae-en/Exhibitor/HKTDC-Hong-Kong-Electronics-Fair-Autumn-Edition/Linkchamp-Co-Ltd/1S0043976/",
        // ],
      },
      {
        year: "2020",
        shows: [
          "Automechanika, Frankfurt (Canceled)",
          "COMPUTEX, Taipei (Canceled)",
          "AMPA, Taipei (Canceled)",
        ],
      },
    ],
  },
  {
    decade: 2010,
    events: [
      {
        year: "2019",
        shows: [
          "Automechanika, Shanghai",
          "HKTDC Electronics Fair, Hong Kong",
          "COMPUTEX, Taipei",
          "AMPA, Taipei",
        ],
        links: [
          "https://www.linkedin.com/posts/linkchamp_automechanika-shanghai-automechanikashanghai-activity-6607473197774409728-GfWT",
          "https://www.linkedin.com/feed/update/urn:li:activity:6588990628145790976",
          "https://www.linkedin.com/feed/update/urn:li:activity:6541144501761732608",
          "https://www.linkedin.com/feed/update/urn:li:activity:6536848529980264448",
        ],
      },
      {
        year: "2018",
        shows: [
          "Automechanika, Shanghai",
          "Global Sources Electronics, Hong Kong",
          "Automechanika, Frankfurt",
          "COMPUTEX, Taipei",
          "AMPA, Taipei",
        ],
      },
      {
        year: "2017",
        shows: [
          "Global Sources Electronics, Hong Kong",
          "TAITRONICS, Taipei",
          "COMPUTEX, Taipei",
          "AMPA, Taipei",
        ],
      },
      {
        year: "2016",
        shows: [
          "Global Sources Electronics, Hong Kong",
          "TAITRONICS, Taipei",
          "AUTOMECHANIKA, Frankfurt",
          "COMPUTEX, Taipei",
          "AMPA, Taipei",
        ],
      },
      {
        year: "2015",
        shows: [
          "Global Sources Electronics Fall, Hong Kong",
          "TAITRONICS, Taipei",
          "INTERSCHUTZ, Hanover",
          "COMPUTEX, Taipei",
          "Global Sources Electronics Spring, Hong Kong",
          "AMPA, Taipei",
          "AUTOMEC, São Paulo",
        ],
      },
      {
        year: "2014",
        shows: [
          "PV Taiwan, Taipei",
          "Global Sources Electronics Fall, Hong Kong",
          "TAITRONICS, Taipei",
          "AUTOMECHANIKA, Frankfurt",
          "COMPUTEX, Taipei",
          "Global Sources Electronics Spring, Hong Kong",
          "AUTOTRONICS, Taipei",
          "AUTOMEC, São Paulo",
        ],
      },
      {
        year: "2013",
        shows: [
          "PV Taiwan, Taipei",
          "Global Sources Electronics Fall, Hong Kong",
          "TAITRONICS, Taipei",
          "Intersolar Europe, Munich",
          "COMPUTEX, Taipei",
          "Global Sources Electronics Spring, Hong Kong",
          "AUTOTRONICS, Taipei",
        ],
      },
      {
        year: "2012",
        shows: [
          "PV Taiwan, Taipei",
          "Global Sources Electronics Fall, Hong Kong",
          "TAITRONICS, Taipei",
          "AUTOMECHANIKA, Frankfurt",
          "COMPUTEX, Taipei",
          "Global Sources Electronics Spring, Hong Kong",
          "AUTOTRONICS, Taipei",
        ],
      },
      {
        year: "2011",
        shows: [
          "Global Sources Electronics Fall, Hong Kong",
          "Equip Auto, Paris",
          "PV Taiwan, Taipei",
          "TAITRONICS, Taipei",
          "COMPUTEX, Taipei",
          "Global Sources Electronics Spring, Hong Kong",
          "AUTOTRONICS, Taipei",
        ],
      },
      {
        year: "2010",
        shows: [
          "Global Sources Electronics Fall, Hong Kong",
          "TAITRONICS, Taipei",
          "AUTOMECHANIKA, Frankfurt",
          "COMPUTEX, Taipei",
          "Global Sources Electronics Spring, Hong Kong",
          "AUTOTRONICS, Taipei",
        ],
      },
    ],
  },
  {
    decade: 2000,
    events: [
      {
        year: "2000s",
        shows: [
          "TAITRONICS, Taipei",
          "AUTOMECHANIKA, Frankfurt",
          "COMPUTEX, Taipei",
          "Global Sources Electronics, Hong Kong",
          "AUTOTRONICS, Taipei",
        ],
      },
    ],
  },
  {
    decade: 1990,
    events: [
      {
        year: "1990s",
        shows: [
          "TAITRONICS, Taipei",
          "COMPUTEX, Taipei",
          "AUTOTRONICS, Taipei",
        ],
      },
    ],
  },
];

function TradeShow(props) {
  const { classes } = props;
  const [news, setNews] = useState([]);
  const [tradeshows, setTradeshows] = useState([]);
  const today = new Date();
  const thisYear = today.getFullYear();
  // console.log("thisYear:" + thisYear);
  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  // const iframeWidth = matchesXS ? 500 : matchesSM ? 350 : matchesMD ? 300 : 300;
  const iframeWidth = "100%";
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch("/data/news.json"); // Assuming that News.json is in the public folder
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const jsonData = await response.json();

        const filteredTradeshows = jsonData.tradeshows.filter(
          (tradeShowDetail) => {
            const postYear = new Date(tradeShowDetail.postDate).getFullYear();
            // console.log("Post Year:", postYear, "This Year:", thisYear); // Debug logging
            return postYear === thisYear;
          }
        );
        console.log("Filtered Tradeshows:", filteredTradeshows); // Debug log to check filtered tradeshows

        setNews(jsonData.news.reverse().slice(0, 3));
        setTradeshows(jsonData.tradeshows);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    }
    fetchData();
  }, [thisYear]); // Empty dependency array means this effect will run once after initial render

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <Helmet>
          <title>LinkChamp - News and Exhibition Information</title>
          <meta
            name="description"
            content="Visit us at the exhibition to check out the latest products LinkChamp has offered, including Inverter, Converter, Battery Charger, and Solar Charge Controller."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={window.location.host + props.match.url}
          />
          <meta
            property="og:title"
            content="LinkChamp - Exhibition Information"
          />
          <meta
            property="og:description"
            content="Visit us at the exhibition to check out the latest products LinkChamp has offered, including Inverter, Converter, Battery Charger, and Solar Charge Controller."
          />
          <meta
            property="og:image"
            content="http://dc-ac.com/images/2019ampa/2019ampa_booth.jpg"
          />
        </Helmet>
        <Typography
          component="h1"
          variant="h5"
          gutterBottom
          color="textSecondary"
          align="center"
        >
          UPCOMING TRADE SHOWS
        </Typography>
        {/* <Divider variant="middle" className={classes.divider} /> */}
        <Paper className={classes.paper}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <Hidden xsDown>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          color="textSecondary"
                        >
                          Date
                        </Typography>
                      </TableCell>
                    </Hidden>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        color="textSecondary"
                      >
                        Exhibition Name
                      </Typography>
                    </TableCell>
                    <Hidden xsDown>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          color="textSecondary"
                        >
                          Location/Website
                        </Typography>
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          color="textSecondary"
                        >
                          Booth/Page
                        </Typography>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tradeshows
                    .filter(
                      (tradeShowDetail) =>
                        new Date(tradeShowDetail.postDate).getFullYear() ===
                        thisYear
                    )
                    .map((tradeShowDetail) => (
                      <TableRow
                        key={tradeShowDetail.name}
                        className={classes.row}
                      >
                        <Hidden xsDown>
                          <TableCell component="th" scope="row">
                            <Typography
                              variant="subtitle1"
                              component="h3"
                              color="textSecondary"
                            >
                              {(
                                tradeShowDetail.start +
                                "~" +
                                tradeShowDetail.end.substring(
                                  tradeShowDetail.end.indexOf("/")
                                )
                              )
                                .split("/")
                                .join(".")}
                            </Typography>
                          </TableCell>
                        </Hidden>
                        <TableCell>
                          <Hidden xsDown>
                            <Typography
                              variant="subtitle1"
                              component="h3"
                              color="textPrimary"
                            >
                              {tradeShowDetail.name}
                            </Typography>
                          </Hidden>
                          <Hidden smUp>
                            <Tooltip
                              title={
                                tradeShowDetail.locationLink.length > 0
                                  ? tradeShowDetail.location.includes("ONLINE")
                                    ? "Open Website"
                                    : "Open in Google Map"
                                  : ""
                              }
                              placement="left"
                            >
                              <Button
                                className={classes.button}
                                onClick={() => {
                                  if (tradeShowDetail.locationLink.length > 0) {
                                    window.open(
                                      `${tradeShowDetail.locationLink}`,
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                {tradeShowDetail.location.includes("ONLINE") ? (
                                  <IconLanguage
                                    className={classNames(
                                      classes.leftIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                ) : (
                                  <IconLocationOn
                                    className={classNames(
                                      classes.leftIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                )}
                                <Badge
                                  className={classes.badge}
                                  badgeContent={
                                    today >=
                                      Date.parse(tradeShowDetail.start) &&
                                    today <= Date.parse(tradeShowDetail.end)
                                      ? "LIVE"
                                      : ""
                                  }
                                  color="secondary"
                                >
                                  {tradeShowDetail.name}
                                </Badge>
                              </Button>
                            </Tooltip>
                            <br />
                            <Button className={classes.button}>
                              <IconToday
                                className={classNames(
                                  classes.leftIcon,
                                  classes.iconSmall
                                )}
                              />
                              {(
                                tradeShowDetail.start +
                                "~" +
                                tradeShowDetail.end.substring(
                                  tradeShowDetail.end.indexOf("/")
                                )
                              )
                                .split("/")
                                .join(".")}
                            </Button>
                            <Tooltip
                              title={
                                tradeShowDetail.boothLink != null &&
                                tradeShowDetail.boothLink.length > 0
                                  ? "Check out our booth!"
                                  : ""
                              }
                              placement="left"
                            >
                              <Button
                                className={classes.button}
                                onClick={() => {
                                  if (
                                    tradeShowDetail.boothLink != null &&
                                    tradeShowDetail.boothLink.length > 0
                                  ) {
                                    window.open(
                                      `${tradeShowDetail.boothLink}`,
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                <IconBooth
                                  className={classNames(
                                    classes.leftIcon,
                                    classes.iconSmall
                                  )}
                                />
                                <Badge
                                  className={classes.badge}
                                  badgeContent={
                                    today >=
                                      Date.parse(tradeShowDetail.start) &&
                                    today <= Date.parse(tradeShowDetail.end)
                                      ? "1"
                                      : ""
                                  }
                                  color="secondary"
                                >
                                  {tradeShowDetail.booth}
                                </Badge>
                              </Button>
                            </Tooltip>
                          </Hidden>
                        </TableCell>
                        <Hidden xsDown>
                          <TableCell>
                            <Tooltip
                              title={
                                tradeShowDetail.locationLink.length > 0
                                  ? tradeShowDetail.location.includes("ONLINE")
                                    ? "Open Website"
                                    : "Open in Google Map"
                                  : ""
                              }
                              placement="left"
                            >
                              <Button
                                className={classes.button}
                                onClick={() => {
                                  if (tradeShowDetail.locationLink.length > 0) {
                                    window.open(
                                      `${tradeShowDetail.locationLink}`,
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                {tradeShowDetail.location.includes("ONLINE") ? (
                                  <IconLanguage
                                    className={classNames(
                                      classes.leftIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                ) : (
                                  <IconLocationOn
                                    className={classNames(
                                      classes.leftIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                )}
                                <Badge
                                  className={classes.badge}
                                  badgeContent={
                                    today >=
                                      Date.parse(tradeShowDetail.start) &&
                                    today <= Date.parse(tradeShowDetail.end)
                                      ? "LIVE"
                                      : ""
                                  }
                                  color="secondary"
                                >
                                  {tradeShowDetail.location}
                                </Badge>
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={
                                tradeShowDetail.boothLink != null &&
                                tradeShowDetail.boothLink.length > 0
                                  ? "Check out our booth!"
                                  : ""
                              }
                              placement="left"
                            >
                              <Button
                                className={classes.button}
                                onClick={() => {
                                  if (
                                    tradeShowDetail.boothLink != null &&
                                    tradeShowDetail.boothLink.length > 0
                                  ) {
                                    window.open(
                                      `${tradeShowDetail.boothLink}`,
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                <IconBooth
                                  className={classNames(
                                    classes.leftIcon,
                                    classes.iconSmall
                                  )}
                                />
                                <Badge
                                  className={classes.badge}
                                  badgeContent={
                                    today >=
                                      Date.parse(tradeShowDetail.start) &&
                                    today <= Date.parse(tradeShowDetail.end)
                                      ? "1"
                                      : ""
                                  }
                                  color="secondary"
                                >
                                  {tradeShowDetail.booth}
                                </Badge>
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>

        <div className={classes.newsBG}>
          <Typography
            component="h1"
            variant="h5"
            gutterBottom
            color="textSecondary"
            align="center"
          >
            NEWS
          </Typography>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            <Grid container spacing={40}>
              {news.map((item) => (
                <Grid item key={item.title} xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardActionArea
                      className={classes.actionArea}
                      component={Link}
                      to={item.link.includes("http") ? `` : `${item.link}`}
                      onClick={() => {
                        if (item.link.includes("http")) {
                          window.open(`${item.link}`, "_blank");
                        }
                      }}
                    >
                      <CardContent className={classes.cardContent}>
                        <iframe
                          src={item.embed}
                          height="450"
                          width={iframeWidth}
                          frameBorder="0"
                          allowFullScreen=""
                          title="Embedded post"
                        ></iframe>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>

        <Typography
          component="h1"
          variant="h5"
          gutterBottom
          color="textSecondary"
          align="center"
        >
          EXHIBITION HISTORY
        </Typography>

        {tradeShowHistory.map((decade) => (
          <ExpansionPanel defaultExpanded key={decade.decade}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant="subtitle1"
                component="h2"
                color="textSecondary"
              >
                {decade.decade}s
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.grid}
              >
                <Grid item xs={4}></Grid>
                <Grid item xs={6}>
                  <Stepper
                    activeStep={-1}
                    orientation="vertical"
                    className={classes.stepper}
                  >
                    {decade.events.map((history) => (
                      <Step key={history.year}>
                        <StepLabel icon={"✓"}>
                          <Typography component="h3" variant="h6">
                            {history.year}
                          </Typography>
                          {history.links ? (
                            <>
                              {history.shows.map((show, index) => (
                                <Typography variant="caption" key={index}>
                                  <Link
                                    component="button"
                                    color="inherit"
                                    align="left"
                                    onClick={() => {
                                      window.open(
                                        `${history.links[index]}`,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    <Typography variant="caption">
                                      {history.links[index].includes(
                                        "linkedin"
                                      ) ? (
                                        <IconImage
                                          className={classNames(
                                            classes.leftIcon,
                                            classes.iconSmall
                                          )}
                                        />
                                      ) : (
                                        <IconLanguage
                                          className={classNames(
                                            classes.leftIcon,
                                            classes.iconSmall
                                          )}
                                        />
                                      )}

                                      {show}
                                    </Typography>
                                  </Link>
                                </Typography>
                              ))}
                            </>
                          ) : (
                            <>
                              {history.shows.map((show, index) => (
                                <Typography variant="caption" key={index}>
                                  ► {show}
                                </Typography>
                              ))}
                            </>
                          )}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}

        {/* <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item xs={4}></Grid>
          <Grid item xs={6}>
            <Stepper activeStep={-1} orientation="vertical">
              {tradeShowHistory.map((history) => (
                <Step key={history.year}>
                  <StepLabel icon={"✓"}>
                    <Typography component="h3" variant="h6">
                      {history.year}
                    </Typography>
                    {history.links ? (
                      <>
                        {history.shows.map((show, index) => (
                          <Typography variant="caption" key={index}>
                            <Link
                              component="button"
                              color="inherit"
                              align="left"
                              onClick={() => {
                                window.open(
                                  `${history.links[index]}`,
                                  "_blank"
                                );
                              }}
                            >
                              <Typography variant="caption">
                                {history.links[index].includes("linkedin") ? (
                                  <IconImage
                                    className={classNames(
                                      classes.leftIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                ) : (
                                  <IconLanguage
                                    className={classNames(
                                      classes.leftIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                )}

                                {show}
                              </Typography>
                            </Link>
                          </Typography>
                        ))}
                      </>
                    ) : (
                      <>
                        {history.shows.map((show, index) => (
                          <Typography variant="caption" key={index}>
                            ► {show}
                          </Typography>
                        ))}
                      </>
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid> */}
      </div>
    </React.Fragment>
  );
}

TradeShow.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(TradeShow);
export default withTheme(theme)(withStyles(styles)(withWidth()(TradeShow)));
